<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Pedido de inserção - {{ insertionOrder.advertiser ? insertionOrder.advertiser.name : '' }}</h1>
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <div class="m-0">
          <router-link
            :to="{ name: 'insertion.orders.index' }"
            class="btn btn-sm btn-primary"
          >
            Voltar
          </router-link>
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Detalhes</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <template v-if="loader">
            <div class="py-5">
              <Loader size="big" />
            </div>
          </template>
          <template v-else>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">ID</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Identificador interno</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.identifier }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Número PO</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.external_id ? insertionOrder.external_id : '---' }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Tipo</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.type | formatInsertionOrderType }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Anunciante</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8">
                <router-link
                  :to="{ name: 'advertisers.show', params: { id: insertionOrder.advertiser.id } }"
                  class="fw-bold fs-6"
                >
                  {{ insertionOrder.advertiser.name }}
                </router-link>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Empresa</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8">
                <router-link
                  :to="{ name: 'companies.show', params: { id: insertionOrder.advertiser.company.id } }"
                  class="fw-bold fs-6"
                >
                  {{ insertionOrder.advertiser.company.name }}
                </router-link>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor Total do Negócio</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.total_business_amount / 100 | formatMoney(insertionOrder.advertiser.currency) }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor da PI</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.amount / 100 | formatMoney(insertionOrder.advertiser.currency) }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">PI credita valor na plataforma?</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.should_credit_amount_on_platform ? 'Sim' : 'Não' }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7" v-if="insertionOrder.should_credit_amount_on_platform">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor para plataforma</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.amount_for_platform / 100 | formatMoney(insertionOrder.advertiser.currency) }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Percentual de comissão</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.commission > 0 ? `${insertionOrder.commission}%` : 'Não se aplica' }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Perfil de cobrança</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.billing_profile }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Forma de pagamento</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.payment_method | formatPaymentMethod }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--begin::Input group-->
            <div class="row mb-7" v-if="insertionOrder.file">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">PI Assinado</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <a :href="insertionOrder.file" target="_blank" class="fw-bold fs-6">Link do arquivo</a>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Ativo</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ insertionOrder.is_active ? 'Sim' : 'Não' }}</span>
              </div>
              <!--end::Col-->
            </div>

            <!-- INSTALLMENTS-->
            <div class="row mb-7">
              <h2>Parcelas</h2>
              <p v-if="insertionOrder.orders.data.length == 0">Observação: Parcelas ainda não emitidas, pendente upload de PI</p>
              <div class="table-responsive">
                <!--begin::Table-->
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
                  <!--begin::Table head-->
                  <thead>
                    <!--begin::Table row-->
                    <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                      <th class="min-w-100px">#</th>
                      <th class="min-w-70px">Parcela ativa</th>
                      <th class="min-w-100px">Valor</th>
                      <th class="min-w-70px">Prazo de pagamento</th>
                      <th class="min-w-70px">Status do pagamento</th>
                      <th class="min-w-70px">Emitir nota</th>
                      <th class="min-w-70px">Data de emissão</th>
                      <th class="min-w-70px">Descrição da nota</th>
                      <th class="min-w-70px">Status da nota</th>
                      <th class="min-w-70px">Ações</th>
                    </tr>
                    <!--end::Table row-->
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody class="fw-semibold text-gray-600">
                    <!--begin::Table row-->
                    <tr v-for="(installment, index) in installments" :key="index">
                      <td>
                        <span>{{ index + 1 }}</span>
                      </td>
                      <td>
                        <span>{{ installment.order_is_active ? 'Sim' : 'Não' }}</span>
                      </td>
                      <td>
                        <span>{{ installment.amount / 100 | formatMoney(insertionOrder.advertiser.currency) }}</span>
                      </td>
                      <td>
                        <span>{{ installment.payment_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                      </td>
                      <td>
                        <span>{{ installment.payment_status | formatPaymentStatus }}</span>
                      </td>
                      <td>
                        <span>{{ installment.should_issue_nfse ? 'Sim' : 'Não' }}</span>
                      </td>
                      <td>
                        <span v-if="installment.should_issue_nfse">{{ installment.nfse_issuance_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                        <span v-else>---</span>
                      </td>
                      <td>
                        <span>{{ installment.nfse_description }}</span>
                      </td>
                      <td>
                        <span>{{ installment.nfse_status | formatNFSeStatus }}</span>
                      </td>
                      <td>
                        <template v-if="installment.order_id">
                          <span>
                            <Button
                              class="btn btn-sm btn-primary"
                              v-b-modal="`order-detail-modal-${installment.order_id}`"
                            >
                              <i class="fas fa-eye px-0"></i>
                            </Button>
                          </span>
                          <OrderDetailModal
                            :orderId="installment.order_id"
                          />
                        </template>
                      </td>
                    </tr>
                    <!--end::Table row-->
                  </tbody>
                  <!--end::Table body-->
                </table>
              </div>
            </div>

            <!--end::Input group-->
            <div class="d-flex flex-column flex-sm-row mt-3 justify-content-center gap-3">
              <!-- Edit -->
              <router-link
                :to="{ name: 'insertion.orders.edit', params: { id: insertionOrder.id } }"
                class="btn btn-primary"
                v-if="
                  insertionOrder.is_active
                "
              >
                Editar
              </router-link><!-- Edit -->
              <!-- Download -->
              <button
                  class="btn btn-warning btn-color-dark"
                  v-b-modal="`insertion-order-download-modal-${insertionOrder.id}`"
                  v-if="!insertionOrder.file && insertionOrder.is_active"
                >
                  <i class="fa-solid fa-download"></i> Download do PI
              </button>
              <InsertionOrderDownloadModal
                :insertionOrder="insertionOrder"
                v-if="!insertionOrder.file && insertionOrder.is_active"
              /><!-- Download -->
              <!-- Upload -->
              <Button
                class="btn btn-info"
                v-b-modal="`insertion-order-upload-modal-${insertionOrder.id}`"
                v-if="!insertionOrder.file && insertionOrder.is_active"
              >
                <i class="fa-solid fa-arrow-up-from-bracket"></i> Upload do PI
              </Button>
              <InsertionOrderUploadModal
                :insertionOrderId="insertionOrder.id"
                v-if="!insertionOrder.file && insertionOrder.is_active"
              /><!-- Upload -->
              <!-- Remove -->
              <Button
                  class="btn btn-danger"
                  v-b-modal="`remove-insertion-order-modal-${insertionOrder.id}`"
                  v-if="insertionOrder.is_active"
                >
                  Remover PI
              </Button>
              <DestroyInsertionOrderModal
                :insertionOrderId="insertionOrder.id"
                @destroyed="reset"
              /><!-- Remove -->
            </div>
          </template>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View-->
      <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import InsertionOrdersService from '@/modules/insertion-orders/services/insertion-orders-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import InsertionOrderUploadModal from '@/components/insertion-orders/InsertionOrderUploadModal'
import InsertionOrderDownloadModal from '@/components/insertion-orders/InsertionOrderDownloadModal'
import OrderDetailModal from '@/components/orders/OrderDetailModal'
import DestroyInsertionOrderModal from '@/components/insertion-orders/DestroyInsertionOrderModal'

export default {
  components: {
    DestroyInsertionOrderModal,
    Button,
    Loader,
    MainLayout,
    InsertionOrderUploadModal,
    InsertionOrderDownloadModal,
    OrderDetailModal
  },
  metaInfo () {
    return {
      title: this.insertionOrder ? 'PI' : ''
    }
  },
  data () {
    return {
      insertionOrder: '',
      loader: false,
      loaderButton: false,
      showAlert: false
    }
  },
  computed: {
    /**
     * Installments depending if have orders or not
    */
    installments () {
      if (!this.insertionOrder) return []
      if (this.insertionOrder.orders.data.length > 0) {
        return this.insertionOrder.orders.data.map(item => {
          const invoice = item.invoices[0]
          invoice.order_id = item.id
          invoice.order_is_active = item.is_active
          invoice.payment_expiration_date = invoice.payment_method === 'BOLETO' ? invoice.boleto_expiration_date : invoice.deposit_expiration_date
          return invoice
        })
      }
      return this.insertionOrder.installments
    },
    invoice () {
      return this.insertionOrder.order && this.insertionOrder.order.invoices.length > 0 ? this.insertionOrder.order.invoices[0] : null
    }
  },
  created () {
    this.getInsertionOrder()
  },
  methods: {
    /**
     * Get insertion order
     */
    async getInsertionOrder () {
      this.loader = true
      try {
        const insertionOrder = await InsertionOrdersService.getInsertionOrder(this.$route.params.id)
        this.insertionOrder = insertionOrder
        this.getInvoiceLogs()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Reset
    */
    reset () {
      this.insertionOrder = ''
      this.getInsertionOrder()
    }
  }
}
</script>
